import './_App.scss';
import './assets/styles/_base.scss';

import {Helmet} from 'react-helmet';
// @ts-ignore
// import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
import ScrollToHashElements from './tools/scroll-to-hash-element'

import { useNavigate, Link } from 'react-router-dom'
//@ts-ignore
import { ReactComponent as Arrow } from './assets/icons/arrow.svg';
//@ts-ignore
import { ReactComponent as ContactIcon } from './assets/icons/email.svg';

import _ from 'lodash';
//@ts-ignore
// import smoothscroll from 'smoothscroll-polyfill';

import React, {useState} from 'react';
import Tools from './tools/Tools';
import Tooltip from './components/Tooltip/Tooltip'

// import Lang, {t} from './tools/Lang';
// import Radio from './tools/Radio';

import data from './assets/data'
import Nav from './components/Nav/Nav'
import ContactModal from './components/ContactModal/ContactModal'
import Experience from './components/Experience/Experience'
import './_App.scss'
import Skills from 'components/Skills/Skills';
import Footer from './components/Footer/Footer';
import { Toaster } from 'react-hot-toast';


export default function App() {
	const [contactModalOpen, setContactModalOpen] = useState(false)

	const [homeBlurbOpen, setHomeBlurbOpen] = useState<string|null>(null)
	const toggleReadMore = (event:React.MouseEvent, id:string) => {
		event.preventDefault()
		let newId:string|null = homeBlurbOpen===id ? null : id
		setHomeBlurbOpen(newId)
		if (newId===null) {
			console.log(`Navigating to home`)
			scrollToElement()
			// navigate("#home", {replace: true})
		}
	}

	const onCvClick = ()=> {
		window.open(data.home.cvPath, "newtab")
	}

	const navigate = useNavigate()

	return <>
	<Helmet>
		<title>Mike Donnelly – Full stack developer</title>
	</Helmet>

	<ScrollToHashElements behavior="smooth" />

	<Nav/>

	<a id="home"></a>
	<main className="app">
		<section className="home big">
			<header>
				<Tooltip text={`Hi!`} className="bubble profilePic" options={ {gapTop: -12, gapBottom: -8, onEvents: ["click", "mouseenter"], offEvents: [ "click", "mouseleave"], duration: 2000 }  }>
					<img src={data.contact.profilePic.path} alt={data.contact.profilePic.alt}/>
				</Tooltip>
				<h2><span className="name">{data.home.name}</span>{" "}<span className="location">{data.home.location}</span></h2>
				<h1>{data.home.title}</h1>
			</header>
			<section className="tools text-column">
				<button className="contact plain link" onClick={()=>setContactModalOpen(true)}>
					<img src="/images/home/email-filled.png" className="icon" aria-hidden="true"/>
					{/* <ContactIcon className="icon" aria-hidden="true"/> */}
					Contact
				</button>
				<button className="cv plain link" onClick={onCvClick} aria-label="Open CV (PDF format) in a new tab">
					<img src="/images/home/button-pdf.png" className="icon" aria-hidden="true"/>CV
				</button>
			</section>

			<div className="text-column">

			{
				data.home.subsections.map(subsection=> {
					const readLess = `<button class="read-less plain link" aria-hidden="true" >...less</button>`
					let content = Tools.fillTags(subsection.detail, {readLess})
					content = addIconsToHtml( content )

					return <div className={`subsection ${subsection.id}`}>
						<div className={`subsection-top ${subsection.id} clearfix`} key={`subsection_${subsection.id}`}>
							<h3 className="subsection-title">
								<img src={subsection.icon.src} aria-hidden="true" />
								<Link to={`/#${subsection.id}`} className="">{subsection.title}</Link>
							</h3>
							<details className={`details ${subsection.id}`} onClick={(e)=>toggleReadMore(e, subsection.id)} open={homeBlurbOpen===subsection.id}>
								<summary className="summary"><p>
									{subsection.summary}
									<button className="read-more plain link" >...more</button>
									</p>

								</summary>
								<div className="content" dangerouslySetInnerHTML={ {__html: content }}>
								</div>
								

							</details>
						</div>
						<div className="subsection-bottom">
							<Link to={`/#${subsection.id}`} className="link">
								<span>{subsection.link}</span><Arrow className="arrow" />
							</Link>
						</div>
					</div>}
				)
			}	
				

			</div>

		</section>


		<Experience />
		<Skills />
		

		<ContactModal open={contactModalOpen} onClose={()=>setContactModalOpen(false)}/>

	</main>
	<Footer/>
	<Toaster/>

	</>
}



function scrollToElement(element?:HTMLElement, smooth=true) {
	window.scrollTo({
		behavior: smooth ? "smooth" : "auto",
		top: element ? element.offsetTop : 0
	});
}



function addIconsToHtml(text:string, template:string=`<span class="icon {iconId}"><span /></span>`) {
	const matches = [...text.matchAll( /{(icon-\S+)}/gi )  ]
	const iconIds = matches.map((match) => match[1])
	iconIds.forEach(iconId=>{
		const iconTag = Tools.fillTags(template, {iconId})
		text = Tools.fillTags(text, {[iconId]: iconTag})
	})
	text = text.replace(/{\s*" "\s*}/g, `&nbsp;`)
	return text;
}

// Code borrowed from Micromodal repo
// https://github.com/ghosh/Micromodal/blob/master/lib/src/index.js

const FOCUSABLE_ELEMENTS = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex^="-"])'
  ]

  export function setFocusToFirstNode (modalElement:HTMLElement, closeElements?:NodeListOf<Element>) {

	// Get a list of all focuable elements
	const focusableEls = getFocusableNodes(modalElement)
	
	// And a list with no close buttons
	const closeElementsArr = [ ...closeElements ]
	const bestElements = focusableEls.filter(node => !closeElementsArr.includes(node))

	if (bestElements.length === 0) {
		focusableEls[0]?.focus()
	}
	else {
		bestElements[0]?.focus()
	}
  }



  export function retainFocus (event:KeyboardEvent, modalElement:HTMLElement) {
	let focusableNodes = getFocusableNodes(modalElement)

	// No focusable nodes
	if (focusableNodes.length === 0) return

	/**
	 * Filters nodes which are hidden to prevent
	 * focus leak outside modal
	 */
	focusableNodes = focusableNodes.filter((node:HTMLElement) => node.offsetParent !== null )

	// if disableFocus is true
	if (!modalElement.contains(document.activeElement)) {
	  focusableNodes[0].focus()
	} else {
	  const focusedItemIndex = focusableNodes.indexOf(document.activeElement as HTMLElement)

	  if (event.shiftKey && focusedItemIndex === 0) {
		focusableNodes[focusableNodes.length - 1].focus()
		event.preventDefault()
	  }

	  if (!event.shiftKey && focusableNodes.length > 0 && focusedItemIndex === focusableNodes.length - 1) {
		focusableNodes[0].focus()
		event.preventDefault()
	  }
	}
  }


  function getFocusableNodes (el:HTMLElement):HTMLElement[] {
	// @ts-ignore
	const nodes = el.querySelectorAll(FOCUSABLE_ELEMENTS)
	return [ ...nodes ];
  }

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {useTimeout} from '../../hooks/useTimeout'

import './_Tooltip.scss'

type NibPosition = "bottom-middle" | "top-middle"
type EventName = "mouseenter" | "click" | "mouseleave"

type Rect = {
	top?:number;
	left?:number;
	right?:number;
	bottom?:number;
	width?:number;
	height?:number;
}
const defaultOptions:TooltipOptions = {
	nibHeight: 10,
	gapBottom: -6,
	gapTop: -6,
	onEvents:  ["mouseenter"],
	offEvents: ["mouseleave"],
	duration: undefined,
}
export type TooltipOptions = {
	nibHeight?: number;
	gapBottom?: number;
	gapTop?: number;
	onEvents?:EventName[];
	offEvents?:EventName[];
	duration?:number,
}

export default function Tooltip({children, text="", className="", options={}}:{children?:React.ReactNode, text?:string, className?:string, options?:TooltipOptions}) {
	options = Object.assign({}, defaultOptions, options)

	const tip = useRef<HTMLDivElement>()
	const target = useRef<HTMLDivElement>()

	const [nibPosition, setNibPosition] = useState<NibPosition>("top-middle")
	const [rect, setRect] = useState<Rect>(()=>calcTipRect())
	const [on, setOn] = useState(false)

	const onTimeout = ()=>{
		setOn(false)
	}
	const [startTimer, stopTimer] = useTimeout()

	function onEvent(eventName:EventName) {
		if (!on) {
			if (options.onEvents.includes(eventName)) {
				setOn(true)
				if (options.duration>0) {
					startTimer(onTimeout, options.duration)
				}
			}
		}
	
		else {
			if (options.offEvents.includes(eventName)) {
				setOn(false)
				stopTimer()
			}
		}
	}

	function calcTipRect():Rect {
		if (!tip.current) return {};

		let {width: tipWidth, height: tipHeight} = tip.current.getBoundingClientRect()
		tipHeight = tipHeight + options.nibHeight + options.gapBottom
		const targetRect = target.current.getBoundingClientRect()

		const enoughSpace = targetRect.top - tipHeight >= 0
		const left = Math.max(0, targetRect.width/2 - tipWidth/2)
		if (enoughSpace) {
			setNibPosition("top-middle")
			return {top: -tipHeight, left}
		}
		else {
			setNibPosition("bottom-middle")
			const offsetY = options.gapTop + options.nibHeight
			return {top: targetRect.height + offsetY, left}
		}
	}

	useLayoutEffect(() => setRect(calcTipRect()), [on])
	

	return <div 
				className={`tooltip-wrapper ${className}`} 
				onMouseEnter={()=>onEvent("mouseenter")} 
				onMouseLeave={()=>onEvent("mouseleave")} 
				onClick={()=>onEvent("click")}
			>

			<div className={`tooltip ${nibPosition} ${on ? "on" : ""}`} ref={tip} style={rect}>
				<span className="bubble">{text}</span>
			</div>

			<div  className="target" ref={target}>
				{children}
			</div>
	</div>
}
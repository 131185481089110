import { useEffect, useRef, useState } from "react"


const LOG = false

type StartArgs = [
	callback: ()=>void,
	duration: number,	
]


/**
 * We want a timer that has a `start(callback, duration)` and a `stop()`.
 * 
 * We keep the callback and duration in the state 
 * and watch them for changes in a useEffect.
 * 
 * Note: The trick is we keep the callback and duration as an `newTimer` array, 
 * which changes only when startTimer() is called.
 * 
 * Once we have a timeout started, we keep it in a ref so we can stop it at any time.
 * 
 */
export function useTimeout():[typeof startTimer, typeof stopTimer] {
	// We store the timeout
	const timeoutRef = useRef<NodeJS.Timeout|null>(null)
	
	// And keep the startTimer() args in state
	const [newTimerArgs, setNewTimerArgs] = useState<StartArgs|null>(null)

	function _start(callback:()=>void, duration:number) {
		_stop()
		if (duration>0) {
			timeoutRef.current = setTimeout(()=> {
				LOG && console.log(`Timeout complete (${timeoutRef.current})`)
				timeoutRef.current = null
				callback()
			}, duration)
			LOG && console.log(`Timeout started (${timeoutRef.current})`)
		}
	}
	function _stop() {
		if (!timeoutRef.current) return;
		clearTimeout(timeoutRef.current)
		LOG && console.log(`Timeout cleared (${timeoutRef.current})`)
		timeoutRef.current = null
	}

	// When the startTimer() args change, we cleanup a previous timer 
	// and start a new one.
	useEffect(()=> {
		if (newTimerArgs) {
			_start(...newTimerArgs)
		}
		// Cleaning up before a newTimerArgs change, or on unmount
		return _stop;
	}, [newTimerArgs])


	function startTimer(...args:StartArgs) {
		setNewTimerArgs(args)
	}
	function stopTimer() {
		setNewTimerArgs(null)
	}

	return [startTimer, stopTimer];
}
import React, { useState } from "react";


import Modal from '../MikiModal/MikiModal'

import './_Footer.scss'

export default function Footer() {
	const [creditsOpen, setCreditsOpen] = useState(false)
	return <>
			<footer>
				<span className="copyright">© Mike Donnelly 2024</span>
				<span className="credits"><button className="plain link" onClick={()=>setCreditsOpen(true)}>Credits</button></span>
			</footer>
			
			<Modal className="credits" open={creditsOpen} onClose={()=>setCreditsOpen(false)}>
				<h2>Credits</h2>

				<p>
					<span className="love">♥</span> Thanks to Darri Donnelly for the design help!
				</p>
				<p>
					Also, thanks to these <a href="https://thenounproject.com" target="newtab">NounProject.com</a> icon designers... 
					<ul className="smallList">
					
						<li>Sweat drop by <a href="https://thenounproject.com/icon/sweat-drop-4884910/" target="newtab">Pronto Illustration</a></li>
						<li>Copy by <a href="https://thenounproject.com/icon/copy-6232141/" target="newtab">Ari Nofiana</a></li>
						<li>Arrow by <a href="https://thenounproject.com/icon/arrow-19036/" target="newtab">Milo Miloezger</a></li>
						<li>Medal by <a href="https://thenounproject.com/icon/medal-6477304/" target="newtab">Liez Art</a></li>
						<li>Envelope by <a href="https://thenounproject.com/icon/envelope-6870183/" target="newtab">Fahmi Mohamad Rivai</a></li>
						<li>Close by <a href="https://thenounproject.com/icon/close-6888295/" target="newtab">Jae Deasigner</a></li>

						{/* <li>Heart 3D by <a href="https://thenounproject.com/Arielpont/" target="newtab">Ariel Pont</a></li> */}
						{/* <li>Skills by <a href="https://thenounproject.com/chanut-is/" target="newtab">Chanut is Industries</a></li> */}
						{/* <li>Molecular Structure, Success by <a href="https://thenounproject.com/paisurangkana/" target="newtab">Template</a> */}
						{/* </li><li>Wise, Creativity, Robot  by <a href="https://thenounproject.com/khld939/" target="newtab">Hamel Khaled</a>		 */}
						{/* </li><li>Generating Idea, Octahedron, Sphere, Pentagon by <a href="https://thenounproject.com/yuluck/" target="newtab">Yu Luck</a></li> */}
						<li>Structure by <a href="https://thenounproject.com/rajkumarraja835/" target="newtab">Daily icons</a></li>
						<li>Anvil by <a href="https://thenounproject.com/lars.online/" target="newtab">Lars Meiertoberens</a></li>
						<li>Treasure Chest by <a href="https://thenounproject.com/tulpahn/" target="newtab">tulpahn</a></li>
						<li>API by <a href="https://thenounproject.com/soremba96/" target="newtab">Soremba</a></li>
						<li>Code by <a href="https://thenounproject.com/heztasia/" target="newtab">Heztasia</a></li>
						<li>Muscle by <a href="https://thenounproject.com/justicon/" target="newtab">Justicon</a></li>
						<li>PDF by <a href="https://thenounproject.com/icon/pdf-file-1506358/" target="newtab">Kyle Miller</a></li>
						{/* <li>Download by <a href="https://thenounproject.com/ohrianid/" target="newtab">Oh Rian</a></li> */}
						{/* <li>Medal by <a href="https://thenounproject.com/icon/medal-1348144/" target="newtab">AomAm</a></li> */}
						{/* <li>Skill by <a href="https://thenounproject.com/thenoun.faisal/" target="newtab">Ahmad</a></li> */}
						{/* <li>Emoji by <a href="https://thenounproject.com/titaniclast/" target="newtab">Böck</a></li> */}
						{/* <li>Design software by <a href="https://thenounproject.com/nhor/" target="newtab">Nhor</a></li> */}
					</ul>	
			

				</p>
			</Modal>
		</>
}


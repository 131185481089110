import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Viewer from 'viewerjs';

import Tools from '../../tools/Tools'

import data from '../../assets/data'
import type {Data, ProjectData, AllBadgesData, BadgeData, ImageData} from '../../assets/data'
import '../../../node_modules/viewerjs/dist/viewer.min.css';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';

// @ts-ignore
import './_Experience.scss'

export default function Experience() {
	return <section className="big experience" key={`section_experience`}>
		<div className="section-column dark">
			<a id="experience"></a>
			<h2 className="sectionTitle">Experience</h2>
			<h3 className="sectionSubtitle">Selected projects</h3>

			{data.projects.map(project => <Project project={project} data={data} key={`Project_${project.id}`}/>)}
			{/* {  [...new Array(100)].map((el:any, i:number)=> <><br/>{i}</>)   } */}

		</div>

	</section>

}


function Project({project, data}:{project:ProjectData, data:Data}) {
	const [galleryWidth, setGalleryWidth] = useState(300)
	const allBadges:AllBadgesData = data.badges.items!
	const badges:BadgeData[] = project.badges?.map((badgeId:string)=> allBadges[badgeId] ).filter(badge=>badge)
	const getBadgeUrl = (filename:string)=>Tools.fillTags(data.badges.url, {filename})

	const onWindowResize = () => {
		const size = window.innerWidth / 3;
		setGalleryWidth(size)
	}
	useEffect(()=> {
		window.addEventListener("resize", onWindowResize)
		return ()=>window.removeEventListener("resize", onWindowResize)
	}, [])

    const handleImageClick = (elId:string) => {
        
        
        console.log(`GalleryPage.handleClick(${elId})`)
        
        const el = document.getElementById(elId);
		// console.log(el)
        // await Tools.later(1);
        
        const viewer = new Viewer(el, {
            inline: false,
            toolbar: false,
            navbar: false,
            title:false,
            viewed() {
                viewer.zoomTo(0.6);
            },
        });
        viewer.view(0)
    }


	return <>
		<a id={`project-${project.id}`}></a>
		<div className="project" key={`project_${project.id}`}>
		<div className="text-column">
			<h3 className="smallcaps subtitle">Project</h3>
			<Link to={`/#project-${project.id}`} className="link">
				<h2 className="title">{project.title}<span className="hash"> #</span></h2>
			</Link>
			{badges?.length>0 && (
				<div className="badges">
					{badges.map((badge, index) =>
						<div className="badge" key={`${project.id}_badge_${index}`}>
							<div className="icon">
								<img src={getBadgeUrl(badge.filename)} alt={badge.alt}/>
							</div>
						</div>
					)}
				</div>
			)}

			<p className="summary">{project.summary}</p>
			<p className="description" dangerouslySetInnerHTML={{__html: project.description}}></p>


			{project.roles && <div className="roles">
				<h3 className="smallcaps">ROLES</h3>
				{project.roles}
			</div>}


			{project.links?.length>0 && <div className="links">
					<h3 className="smallcaps links-title">LINKS</h3>
					{project.links.map( (link, index:number) => (
							<a href={link.url} target="newTab" key={`${project.id}_link_${index}`}>{link.name}</a>
						))
					}
				</div>
			}

			{project.tech && <div className="tech">
				<h3 className="smallcaps">SKILLS & TECHNOLOGY</h3>
				{project.tech}
			</div>}

		</div>


		<div className="images" id={`project-${project.id}-images`}>
			{project.images?.map((pic:ImageData, index:number) => {
				const id=`${project.id}_image_img_${index}_new`
				return (<>
						{pic.type==="youtube" &&
							<div className="video" key={`${id}_video`}>
								<YoutubeEmbed url={pic.url} />
							</div>
						}

						{pic.type==="image" && 
							<button className="plain" onClick={()=>handleImageClick(id)} key={`${id}_button`}>
								<img src={pic.path} id={id} alt={pic.alt} />
							</button>
						}
						
				</>)
			})}
		
			
		</div>

	</div>
	</> 
}

import React, { useEffect, useState } from "react"
import toast from 'react-hot-toast';

import Modal from '../MikiModal/MikiModal'
import data from '../../assets/data'
// @ts-ignore
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';

import './_ContactModal.scss'

export default function ContactModal({open, onClose:onDestroy}:{open:boolean, onClose:()=>void}) {
	const [init, setInit] = useState("init")
	const onClose = ()=> {
		onDestroy()
	}

	const onCopy = (text:string) => {
		toast('Text copied', {duration: 1500, position: "bottom-center"})
	}
	function removeInit(e:React.SyntheticEvent) {
		(e.target as HTMLElement).classList.remove("init")
	}

	// The little "Get in touch" bubble needs its init class removed to start the transition
	useEffect(()=>{
		setInit(open ? "" : "init")
	}, [open])

	return (
		<Modal className="contact" open={open} onClose={onClose}>
				<div className="column1 pic">
					<h1 className={init} >Get in touch :)</h1>
					<img src={data.contact.profilePic.path} alt={data.contact.profilePic.alt}/>
				</div>
				<div className="column2">
					<h3>{data.home.name}</h3>
					<h4>{data.home.title}</h4>
					<p>
						<div>Email:</div>
						<div className="email">
							<a href={`mailto: ${data.contact.email}`} target="newtab">
								{data.contact.email}
							</a>
							<button onClick={()=>onCopy(data.contact.email)} className="plain copy">
								<CopyIcon className="icon" />
							</button>
						</div>
						
						<div>LinkedIn:</div>
						<div>
							<a href={data.contact.linkedIn.url} target="newtab">
								{data.contact.linkedIn.title}
							</a>
						</div>
					</p>
				</div>
		</Modal>
	)
}
import './_Nav.scss';

import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useDebounce } from "@uidotdev/usehooks";

// @ts-ignore
import { ReactComponent as ContactIcon } from '../../assets/icons/email.svg';
import ContactModal from 'components/ContactModal/ContactModal';


export default function Nav() {
	const [showHeader, setShowHeader] = useState(false);
	const [scrollPos, setScrollPos] = useState(0);
	const [contactModalOpen, setContactModalOpen] = useState(false)

	const debouncedScrollPos = useDebounce(scrollPos, 50)

	const handleScroll = () => {
		const top = document.body.getBoundingClientRect().top
		const isScrollingUp = top > debouncedScrollPos
		setScrollPos(top);
		setShowHeader(isScrollingUp);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
		  window.removeEventListener("scroll", handleScroll);
		};
	});

	return <>
		<nav className={`nav ${showHeader ? "show" : "hide"}`}>
			<ul>
				<li>
					<Link to="/#home">
						Home
					</Link>
				</li>
				<li>
					<Link to="/#experience">
						Experience
					</Link>
				</li>
				<li>
					<Link to="/#skills">
						Skills
					</Link>
				</li>
				<li>
					<button className="email plain link" onClick={()=>setContactModalOpen(true)}>
						<ContactIcon className="icon"/>
					</button>
					{/* <Link to="/#contact" className="email" onClick={()=>setContactModalOpen(true)}>
						<Email className="icon"/>
					</Link> */}
				</li>
			</ul>

		</nav>
		<ContactModal open={contactModalOpen} onClose={()=>setContactModalOpen(false)}/>
		
	</>
}
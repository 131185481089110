import React from "react";

import './_Skills.scss'
import data from '../../assets/data'


export default function Skills() {

	return <section className="big skills" key={`section_skills`}><a id="skills"></a>
			
		<h2 className="sectionTitle">Skills</h2>
		<div className="section-column">

	
			{data.skills.groups.map(group=> (
				<div className="group" key={`group_${group.id}`}>
					<div className="header">
						{group.icon &&
							<img src={`/images/skills/${group.icon}`} aria-hidden="true"/>
						}
						<h3 dangerouslySetInnerHTML={ {__html: group.title}}></h3>
					</div>
					<ul className="items">
						{group.items.map((item, i)=>(
							<li className={`item ${item.level}`} key={`group_${group.id}_skill_${i}` }>
								<span className="title">
									{item.title}
									{item.titleNote && <span className="titleNote">{item.titleNote}</span>}
								</span>
								{item.subtitle &&
									<span className="subtitle">{item.subtitle}</span>
								}
							</li>
						))}
					</ul>
				</div>
			))}

		</div>

	</section>

}
import React, { useEffect, useRef, useState } from "react";

import {setFocusToFirstNode, retainFocus} from './modal-tools';

// @ts-ignore
import {ReactComponent as Close} from '../../assets/icons/close.svg'

import './_MikiModal.scss'
 
export default function Modal({children, className="", open, onClose: onDestroy}:{className:string, open:boolean, onClose:()=>void, children: React.ReactNode}) {
	const scrim = useRef<HTMLDivElement|null>(null)
	const focusedElement = useRef<HTMLElement|null>(null)
	
	const onClose = () => {
		onDestroy()
	}
	const onScrimClick = (e:React.MouseEvent)=> {
		const clickedScrim = e.target===scrim.current
		if (clickedScrim) {
			onClose()
		}
	}
	useEffect(()=> {
		if (open===true) {
			document.body.classList.add(`prevent-scrolling`)
		}
		else {
			document.body.classList.remove(`prevent-scrolling`)
		}
	}, [open])


	const onKey = (e:KeyboardEvent) => {
		// console.log(e.key)
		if (e.key==="Escape") {
			onClose()
		}
		if (e.key==="Tab") {
			retainFocus(e, scrim.current)
		}
	}
	useEffect(()=> {
		window.addEventListener("keydown", onKey)
		return ()=>window.removeEventListener("keydown", onKey)
	})

	// Set the focus on open and close
	useEffect(()=> {
		if (open) {
			focusedElement.current = document.activeElement as HTMLElement
			const closeButtons = scrim.current.querySelectorAll(`.modal-close`)
			setFocusToFirstNode(scrim.current, closeButtons)
		}
		else {
			focusedElement.current?.focus?.()
		}
	}, [open])

	return (
		<div className={`modal ${open? "open": ""} ${className}`}>
			<div className="modal-inner" onClick={onScrimClick} ref={scrim}>
				<dialog className="frame" aria-modal="true" open={open}>
					<button className="modal-close" aria-label="Close modal" onClick={onClose}>
						<Close className="label"/>
					</button>
					<div className="content">
						{children}
					</div>
				</dialog>
			</div>
		</div>
	)
}
